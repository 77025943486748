<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4"> 
    <div class="container" id="custom-cards">
      <h3 class="mt-3 pb-0 border-bottom text-dark-blue">Profile </h3>  
      <div class="row row-cols-1 row-cols-lg-2 align-items-stretch g-4 py-5"> 
        <div class="col">               
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div class="container">  
                <form class="row g-3 justify-content-center align-items-center">
                  <div class="col-md-4" align="center">
                    <img src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png" class="profile-picture avatar img-circle img-thumbnail" alt="avatar">
                  </div>
                  <div>
                    <h6>Uman Korhs</h6>
                    <h6>Jnuior Developer</h6>
                  </div>                              
                  <h6 class="text-start cursor-text" @click="picture = !picture">Change Picture</h6> 
                  <div id="changepic" v-show="picture" class="col-md-8 align-items-center">                                      
                    <label for="formFile" class="form-label">Upload a different photo</label>
                    <input class="file-button form-control form-control-sm file-upload"  type="file" id="formFile">
                  </div>
                  <h6 class="text-start">Change Email</h6>
                  <h6 class="text-start">Change Password</h6>
                  <h6 class="text-start">Desactivate Account</h6>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col">               
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <h6 class="card-title"></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main> 
</template>

<script>
  export default
  {
    name: 'ContentEditMember',
    el: '#changepic',
    data() 
    {
      return 
      {
        picture: false
      }
    }
  }
</script>

<style scoped></style>
